<template>
<div class="professional-service-area service-area-design-agency pt-120 pb-96">
	<div class="container">
		<div class="row">
			<div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-12">
				<div class="section-title white-2 text-center">
					<h4>What We Are Expert At</h4>
					<h1 class="mt-3">サービス内容</h1>
				</div>
			</div>
		</div>
		<div class="row mt-60">
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-1.png" alt="">
						<span>01</span>
					</div>
					<h3>開発・保守</h3>
					<p class="mt-4">お客様のご要望に応じた「OS、DB、言語、ツール、フレームワーク」によるソフトウエア開発・保守サービス。 汎用コンピュータの開発、コンバージョン、マイグレーションサービス。</p>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-2.png" alt="">
						<span>02</span>
					</div>
					<h3>CRMシステム</h3>
					<p class="mt-4">お客さまのご要望にきめ細かく対応する個別開発と、あらゆるお客さまに対応できるCRMパッケージの保守・運用のアウトソーシングサービスなど、お客様に最適なソリューションをご提供します。</p>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-3.png" alt="">
						<span>03</span>
					</div>
					<h3>WEBシステム</h3>
					<p class="mt-4">お客様のご要望に柔軟に対応する最適なシステムについて、各種携帯、ブラウザ等、各ベンダの端末に対応したフロントからバックエンドまでのWebシステムをご提供します。　　　　　　　　　</p>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-4.png" alt="">
						<span>04</span>
					</div>
					<h3>オフショア開発</h3>
					<p class="mt-4">ブロックチェーン開発、CRMシステム開発、組込み系システム開発、コンサルティング、データ‐マイニング、OCR開発、ウェブ‐クローラーなど。&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</p>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="business-video-area">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-8 col-12">
				<div class="business-video-title text-center" data-aos="fade-right" :data-aos-duration="500">
                    <h2 style="color: #fff;line-height: 60px;">社員と会社が共にある。活躍できる舞台がこにある。</h2>
					<h3 style="color: #fff;line-height: 40px;font-size: 20px;">システムエンジニアを目指す方を求めています。全学部・全学科からの募集で、社員の半数以上が文系出身、プログラミング未経験者です。ITのスペシャリストへ成長できる環境をご用意して、お待ちしています。</h3>
				</div>
				<div class="business-video-wrap d-flex justify-content-center" data-aos="fade-right" :data-aos-duration="500">
					<a href="/recruit" class="popup-youtube d-flex justify-content-center align-items-center "><i class="bi bi-play-fill text-white"></i></a>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="blog-area pt-120 pb-96">
	<div class="container">
		<div class="row">
			<div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-12">
				<div class="section-title text-center">
					<h4>Our Blog Posts</h4>
					<h1 class="mt-3">お知らせ</h1>
				</div>
			</div>
		</div>
		<div class="row mt-60">
			<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-right" :data-aos-duration="500" v-for="(item, index) in newsList" :key="index">
				<div class="blog-wrap overflow-hidden">
					<div class="blog-img overflow-hidden">
						<router-link :to="`/news/${item.id}`"><img class="w-100" :src="item.img" alt="" style="width:370px;height:220px;"></router-link>
					</div>
					<div class="blog-content">
						<p class="blog-meta"><router-link :to="`/news/${item.id}`">FEC News</router-link> <span>|</span> {{ item.time }}</p>
						<h3 class="mt-3 mb-3"><router-link :to="`/news/${item.id}`">{{ item.title }}</router-link></h3>
						<div class="blog-btn">
							<router-link :to="`/news/${item.id}`" class="icon-btn"><i class="bi bi-arrow-right"></i></router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>


<script>
// banner設定
function slickDots (){
    if($('.slick-dots').length) {
        let slickList = $('.slick-dots')[0].childNodes;
        if (slickList.length) {
            var point = 1;
            $('#bannercroll').css('background-image','')
            for (var i=1; i <= slickList.length; i++) {
                point = i;
                const className = slickList[i-1].className;
                if(i==1 && className) { 
                    $('#bannercroll').css('background-image','')
                    $('#bannercroll').css('background-image','/images/first_banner.jpg');
                }
                else if(i==2 && className) {
                    $('#bannercroll').css('background-image','-webkit-radial-gradient(50% 50%, circle closest-side, rgba(11, 60, 175, 0.8) 0%, #0c44b3 100%)');
                    // -webkit-radial-gradient(50% 50%, circle closest-side, rgba(11, 60, 175, 0.8) 0%, #0c44b3 100%);
                } else if(i==3 && className) {
                    $('#bannercroll').css('background-image','-webkit-radial-gradient(50% 50%, circle closest-side, rgba(47, 18, 137, 0.7) 0%, #4f1eab 100%)');
                } else {
                    //   $('#bannercroll').css('background-image','/images/first_banner.jpg');
                }
            }
        }
    }
}
window.setInterval(slickDots, 100);
import { onMounted, ref,onUnmounted } from "vue";
export default {
  name: "Home",
  setup() {
   
    const newsList = ref([]);
    const getNewsList = () => {
      $.ajax({
        type: "GET",
        url: "/api/news.php",
        dataType: "json",
        data: {
          page: 1,
        },
        success: (res) => {
          newsList.value = res.list.slice(0, 3);
        },
      });
    };
    onMounted(() => {
      getNewsList();
      resize();
    //   initSlick();
    });
    function resize() {
      const height = $(window).height();
      $('#bannercroll').css('height',height);
    }
    window.addEventListener('resize', resize)
    onUnmounted(() => {
      window.removeEventListener('resize', resize)
    })
    return { newsList };
  },
  computed: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
};
</script>

<template>
<!-- Start FEC breadcrumb section -->
<div class="breadcrumb-area breadcrumb-area-info2 main-banner">
    <div class="pattern-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Our News</h4>
                        <h1 class="mt-3">お知らせ</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End FEC breadcrumb section -->
<section class="blog-grid">
  <div class="container">
      <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span>News</span></p>
        </div>
      <div class="row high-gutters">
          <div class="col-lg-4 col-md-6 col-sm-12" v-for="(item,index) in newsList" v-bind:key="index" data-aos="fade-right" :data-aos-duration="500">
              <div class="blog-one__single">
                  <div class="blog-one__image" style="height: 200px;overflow: hidden;">
                     <img v-if="item.img" :src="item.img" style="height: 200px;overflow: hidden;"/>
                      <img v-else src="/assets/images/blog/blog-1-1.jpg" style="height: 200px;overflow: hidden;"/>
                      <a :href="`/news/${item.id}`"><i class="fal fa-plus"></i></a>
                  </div>
                  <!-- /.blog-one__image -->
                  <div class="blog-one__content">
                      <div class="blog-one__meta">
                         {{item.time?item.time:'2022-1-1 00:00:00'}}
                      </div>
                      <!-- /.blog-one__meta -->
                      <h3 style="font-size:16px;word-break:break-all;word-wrap:break-word;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"><a :href="`/news/${item.id}`">{{item.title?item.title:''}}</a></h3>
                      <a :href="`/news/${item.id}`" class="thm-btn blog-one__btn"><span>Read More</span></a>
                      <!-- /.thm-btn blog-one__btn -->
                  </div>
                  <!-- /.blog-one__content -->
              </div>
              <!-- /.blog-one__single -->
          </div>
      </div>
      <!-- /.row -->
      <div class="post-pagination">
          <a href="/news">1</a>
          <a href="/news"><i class="far fa-angle-right"></i></a>
      </div>
      <!-- /.post-pagination -->
  </div>
  <!-- /.container -->
</section>
</template>

<script>
import {onMounted,ref} from "vue"
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "News",
  components:{ BreadCrumb },
  setup() {
    const newsList = ref([])
    const page = ref(1)
    const pageSize = ref(10)
    const count = ref(0)
    let loading = ref(false)
    const getNewsList = ()=>{
      loading.value = true
      $.ajax({
        type: "GET",
        url: "/api/news.php",
        dataType:"json",
        data:{
          page: page.value
        },
        success:(res)=>{
          console.log(res)
          newsList.value = res.list
          count.value = res.count
          loading.value = false
          console.log(newsList.value)
        }
      })
    }
    const prev = ()=>{
      if(!loading.value){
        page.value--
        getNewsList()
      }
    }
    const next = ()=>{
      if(!loading.value){
        page.value++
        getNewsList()
      }
    }
    onMounted(() => {
      getNewsList()
    })
    return {newsList,page,pageSize,count,prev,next}
  }
}
</script>

<style scoped>

</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementUI from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
    offset: 20,
    delay: 300, // values from 0 to 3000, with step 50ms
    duration: 800 // values from 0 to 3000, with step 50ms
});
let HomeInfo = {
    Tel: '03-6673-9018',
    Fax: '',
    PostCode: '103-0014',
    Email: 'info@fecsys.co.jp',
    Address: '東京都中央区日本橋蛎殻町１丁目１１番３号',
    Building: '中銀日本橋マンシオン２０１室',
    CompanyName: '株式会社FECソリューションズ',
    CompanyNo: 'FEC Solutions Co., Ltd.',
    Abbreviation: '（略称：FEC）',
    Representative: '李　超',
    Katakana: 'リ　チョウ',
    Capital: '300',
    Banks: 'abc<br/>zxzzx',
    CreateDate: '2023年03月01日',
    YearSell: '',
    HakenNo: '',
    CTO: '',
    MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2138.109432855845!2d139.78148222193124!3d35.68270731692984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018894b6c92c2b7%3A0xa704c44762febab3!2z5qCq5byP5Lya56S-RkVD44K944Oq44Ol44O844K344On44Oz44K6!5e0!3m2!1sja!2sjp!4v1679385697109!5m2!1sja!2sjp'
}
const app = createApp(App)
    // load 
$.ajax({
    type: "GET",
    url: "/api/company.php",
    dataType: "json",
    success: (res) => {
        HomeInfo = res
        app.config.globalProperties.HomeInfo = res
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
    error: (res) => {
        app.config.globalProperties.HomeInfo = HomeInfo
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
});
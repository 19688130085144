<template>
  <!-- Start FEC breadcrumb section -->
  <div class="breadcrumb-area breadcrumb-area-info main-banner">
    <div class="pattern-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>News</h4>
                        <h1 class="mt-3">{{news.data.title?news.data.title:""}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <!-- End FEC breadcrumb section -->
  <!-- Start reinfo_breadcrumb section -->
  <div v-if="!news.data" class="blank"></div>
  <section class="bg_image" v-if="news.data" style="margin-top:50px;">
      <div class="container">
       <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span>{{news.data.title}}</span></p>
        </div>
        <component :is="currentTab" class="cantact_box_margin_top"></component>
      </div>
  </section>
  <!-- End reinfo_breadcrumb section -->
  <!-- Start reinfo_single_blog section -->
  <section class="reinfo_single_blog section_padding" v-if="news.data">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="single_blog_wrapper" data-aos="fade-right" :data-aos-duration="500">
            <div class="post_content" v-html="news.data.content" id="post_content"></div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <br/>
  </section>
  <!-- End reinfo_single_blog section -->
</template>
<script>
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BreadCrumb from "../components/BreadCrumb";

export default {
  name: "NewsDetail",
  components:{ BreadCrumb},
  setup() {
    const route = useRoute()
    const router = useRouter()

    const news = reactive({
      data: null,
    })
    const loading = ref(false)
    const subName = ref('')
    const getNews = () => {
      loading.value = true
      $.ajax({
        type: 'GET',
        url: '/api/get_news.php',
        dataType: 'json',
        data: {
          id: route.params.id,
        },
        success: (res) => {
          console.log(res)
          news.data = res.news
          document.title = res.news.title
          subName.value = res.news.title
          loading.value = false
        },
      })
    }
    onMounted(() => {
      getNews()
    })
    return { news ,subName}
  },
}
</script>
<style lang=""></style>

<template>

<a href="#" id="scroll-top" class="back-to-top-btn"><i class="bi bi-arrow-up"></i></a>

<header>
<nav>
	<div class="header-menu-area agency-header">
		<div class="container-fluid">
			<div class="row align-items-center">
				<div class="col-xxl-2 col-xl-2 col-lg-2 col-sm-6 col-6 order-0 order-lg-0">
					<div class="logo text-left">
						<a href="/"><img src="/assets/images/footer-logo.png" alt=""></a>
					</div>
				</div>
				<div class="col-xxl-7 col-xl-6 col-lg-7 col-sm-1 col-1 order-2 order-lg-1">
					<a href="javascript:void(0)" class="hidden-lg hamburger">
						<span class="h-top"></span>
						<span class="h-middle"></span>
						<span class="h-bottom"></span>
					</a>
					<nav class="main-nav">
						<div class="logo mobile-ham-logo d-lg-none d-block text-left">
							<a href="/"><img src="/assets/images/footer-logo.png" alt=""></a>
						</div>
						<ul>
							<li>
								<a href="/" :class="{'active':pathType==='home'}">ホーム</a>
							</li>
							<li>
                                <a href="/about" :class="{'active':pathType==='about'}">会社情報</a>
                            </li>
							<li>
								<a href="/business" :class="{'active':pathType==='business'}">事業内容</a>
							</li>
							<li>
								<a href="/service" :class="{'active':pathType==='service'}">サービス</a>
							</li>
							<li><a href="/access" :class="{'active':pathType==='access'}">アクセス</a></li>
							<li><a href="/recruit" :class="{'active':pathType==='recruit'}">採用情報</a></li>
							<li><a href="/contact" :class="{'active':pathType==='contact'}">お問い合わせ</a></li>
						</ul>
					</nav>
				</div>
				<div class="col-xxl-3 col-xl-4 col-lg-3 col-sm-5 col-5 order-1 order-lg-2">
					<div class="menu-btn-wrap">
						<div class="contact-info menu-contact-info d-flex align-items-center">
                     <div class="contact-info-icon">
                        <img src="/assets/images/icon/phone.png" alt="">
                     </div>
                     <div class="contact-info-details">
                        <h5>Contact Us</h5>
                        <a href="tel:0366739018">03-6673-9018</a>
                     </div>
                  </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</nav>
<!-- Hero Area -->
<div class="hero-slide-wrap owl-carousel" id="hero-slide">
	<div class="hero-slide-item hero-slide-item-agency position-relative overflow-hidden">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="hero-content position-relative text-center">
						<h2 style="line-height:45px;">人材こそが最大の財産<br/></h2>
						<h2  style="line-height:45px;" class="mt-1 mt-lg-0">徹底した教育により、お客様へのサービス精神を持ち、<br/>日本のビジネス習慣を身に付け、豊富な技術ノウハウ・業務ノウハウを有した、<br/>真のSE人材を育成して参ります。</h2>
						<div class="hero-link mt-4 mt-lg-5 d-flex justify-content-center">
							<a class="common-btn" href="/contact">Contact Us<i class="bi bi-arrow-right"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="image-layer" style="background-image: url(/assets/images/hero/hero-agency-2.png);"></div>
		<img class="hero-agency-sp-1 position-absolute" src="/assets/images/shape/hero-agency-side-left.png" alt="">
		<img class="hero-agency-sp-2 position-absolute" src="/assets/images/shape/hero-agency-side-right.png" alt="">
		<img class="hero-agency-sp-3 position-absolute" src="/assets/images/shape/hero-agency-top-right.png" alt="">
	</div>
	<div class="hero-slide-item position-relative overflow-hidden">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="hero-content position-relative text-center">
						<h2 style="line-height:45px;">信用・信頼はビジネス成り立ちの基礎<br/></h2>
						<h2 style="line-height:45px;" class="mt-1 mt-lg-0">“有言実行”、お客様からの御満足と御信頼を得る事を最も重視し、<br/>頂いた仕事は必ずやり遂げ、常に高品質で低コストのサービスを提供する事により、<br/>お客様との厚い信頼関係を構築して参ります。</h2>
						<div class="hero-link mt-4 mt-lg-5 d-flex justify-content-center">
							<a class="common-btn" href="/service">Our Service <i class="bi bi-arrow-right"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="image-layer" style="background-image: url(/assets/images/hero/hero-agency.png);"></div>
		<img class="hero-agency-sp-1 position-absolute" src="/assets/images/shape/hero-agency-side-left.png" alt="">
		<img class="hero-agency-sp-2 position-absolute" src="/assets/images/shape/hero-agency-side-right.png" alt="">
		<img class="hero-agency-sp-3 position-absolute" src="/assets/images/shape/hero-agency-top-right.png" alt="">
	</div>
</div>
<!-- Hero Area End -->
</header>
</template>

<script>
import { onMounted,ref,watch } from '@vue/runtime-core'
import { useRoute} from "vue-router"
export default {
  name: "Header",
  setup() { 
    const route = useRoute();
    const pathType = ref('')
    const ww = Window.innerWidth;
    watch(
      () => route.meta.pathType,
      newPathType => {
        pathType.value = newPathType;
		if(newPathType=="home") {
		  document.getElementById('hero-slide').style.display='block';
		} else {
			 document.getElementById('hero-slide').style.display='none';
		}
      }
    )
    onMounted(()=>{
       pathType.value = route.meta.pathType || location.pathname.substring(1);
       if(window.innerWidth < 900) {
        //   $('#'+ pathType.value  + '_current').addClass('current');
       }
    })
    return {pathType,ww}
  }
}
</script>